<template>
  <div class="GeneralActions">
    <general-action-settings @updateGeneralActions="() => { updateGeneralActions() }"/>
    <div class="GeneralActions__title">
      <div class="title_text">
        General Actions
      </div>
      <v-menu
          right
          content-class="ActionList"
          rounded="lg"
          :close-on-content-click="false"
          v-model="showMenu"
          :attach="true"
      >
        <template v-slot:activator="{ attrs, on }">
          <div
              class="ActionList__plus-block"
          >
            <v-icon
                color="#374151"
                v-bind="attrs"
                v-on="on"
                class="ActionList__action"
            >
              mdi-plus
            </v-icon>
          </div>
        </template>

        <v-card
            class="ActionList__menu-list"
            max-width="280"
            max-height="365"
            rounded="lg"
        >
          <v-card-title>
            <v-text-field
                label="Search Actions"
                outlined
                dense
                :hide-details="true"
                v-model="searchValue"
            />
          </v-card-title>

          <v-card-text class="ActionList__menu-list">
            <v-list>
              <v-list-item-group
                  v-for="connector in actions"
                  :key="connector.type_id"
              >
                <template v-if="connector.name">
                  <v-subheader>{{ connector.name }}</v-subheader>
                  <template v-if="connector.actions">
                    <v-list-item
                        v-for="action in excludeAction(connector.actions)"
                        :key="action.type_id"
                        @click="addNode(connector, action)"
                        two-line
                    >
                      <ActionIcon :action="action" :color="connector.color" />

                      <v-list-item-content class="ActionList__menu-text">
                        <v-list-item-title class="ActionList__menu-title">
                          {{ action.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ action.descr || '** provide description plz **' }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-else>
                    <v-list-item @click="addNode(connector)">
                      <ActionIcon :action="connector" />

                      <v-list-item-content two-line>
                        <v-list-item-title class="ActionList__menu-title">
                          {{ connector.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ connector.descr || '** provide description plz **' }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
    <div class="GeneralActions__container">
      <div v-for="generalAction in generalActions" :key="generalAction.id">
        <div>
          <div
              class="GeneralActions__item"
              @click="setAction(generalAction)"
              :style="borderStyle"
          >
            <div class="GeneralActions__item__header d-flex align-center">
              <div class="GeneralActions__item__header-icon">
                <ActionIcon :action="generalAction" />
              </div>
              <div class="GeneralActions__item__header-title">
                {{ actionType(generalAction) || 'Choose action' }}
              </div>
            </div>
            <div class="GeneralActions__item__content">
              {{ generalAction.name }}
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ActionIcon from "@/components/action-icon/action-icon.vue";
import {actionsTypes, nestedActionTypes, omitUIFields} from '@/util/action-types';
import cloneDeep from "lodash/cloneDeep";
import GeneralActionSettings from "@/views/ai-workspaces/workspace/general-actions/action-settings/action-settings.vue";
import uuidv4 from "@/util/uuid_generator";

const { mapGetters: workspacesGetters, mapActions: workspaceActions, mapMutations: workspaceMutations } = createNamespacedHelpers('aiWorkspace');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'GeneralActions',
  components: {GeneralActionSettings, ActionIcon},
  props: {
    jsonKnowledge: {
      type: Object,
    }
  },
  data() {
    return {
      showMenu: false,
      searchValue: '',
      borderStyle: {
        borderColor: 'transparent',
      },
    }
  },
  computed: {
    ...workspacesGetters({
      workspace: 'WORKSPACE',
      generalActions: 'WORKSPACE_GENERAL_ACTIONS'
    }),
    ...customerGetters({
      customer: 'CUSTOMER',
    }),
    actionsTypes() {
      const name = this.customer.CustomersFeature.data_source_name;
      let at = actionsTypes(name);
      if (this.isNestedActions) {
        at = nestedActionTypes();
      }
      return at;
    },
    actions() {
      const actionsTypes = this.excludeAction(this.actionsTypes);
      if (!this.searchValue) return actionsTypes;
      const searchValue = this.searchValue.toLowerCase();
      const result = [];

      for (let i = 0; i < actionsTypes.length; i++) {
        const group = cloneDeep(actionsTypes[i]);
        if (
            group.name?.toLowerCase().includes(searchValue) ||
            group.display_name?.toLowerCase().includes(searchValue) ||
            group.action_type?.toLowerCase().includes(searchValue) ||
            group.descr?.toLowerCase().includes(searchValue)
        ) {
          result.push(group);
          continue;
        }

        if (group.actions) {
          const actions = group.actions;
          group.actions = [];

          actions.forEach((action) => {
            if (
                action.name?.toLowerCase().includes(searchValue) ||
                action.display_name?.toLowerCase().includes(searchValue) ||
                action.action_type?.toLowerCase().includes(searchValue) ||
                action.descr?.toLowerCase().includes(searchValue)
            ) {
              group.actions.push(action);
            }
          });

          if (group.actions.length > 0) {
            result.push(group);
          }
        }
      }
      return result;
    },
  },
  methods: {
    ...workspaceActions(['updateWorkspace']),
    ...workspaceMutations({
      setGeneralActions: 'SET_GENERAL_ACTIONS',
      setSelectedAction: 'SET_SELECTED_ACTION'
    }),
    setAction(action) {
      this.setSelectedAction(action)
    },
    excludeAction(actions) {
      return actions?.filter((action) => action.aiAction && !this.getAddedActions().includes(action.action_type));
    },
    actionType(action) {
      return (
          action.display_name ||
          (action.action_type || '').split('_').join(' ')
      );
    },
    addNode(connector, action) {
      this.showMenu = false;
      const node = cloneDeep(connector);
      node.id = uuidv4();
      if (action) {
        node.name = action.name;
        node.display_name = action.display_name;
        node.action_type = action.action_type;
        node.descr = action.descr;
        node.icon = action.icon ?? connector.icon;
        node.color = action.color ?? connector.color;
        node.svgIcon = action.svgIcon;
        node.settings = {
          ...node.settings,
          ...action.settings,
        };
      }
      const actions = cloneDeep(this.generalActions);
      actions.push(node);
      this.setGeneralActions(actions);
      this.setAction(node);
    },
    updateGeneralActions() {
      const generalActions = omitUIFields(this.generalActions);
      this.updateWorkspace({
        workspaceId: this.workspace.id,
        general_actions: generalActions,
      })
    },
    getAddedActions() {
      return this.generalActions.map((action) => action.action_type);
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'general-actions';
</style>
