<template>
  <v-dialog
      @click:outside="closeModal"
      :value="selectedAction"
      max-width="500px"
      scrollable
      content-class="GeneralActionSettings"
  >
    <v-card class="GeneralActionSettings__card">
      <div v-if="selectedAction" class="GeneralActionSettings__content px-4">
        <p class="GeneralActionSettings__node-type text-h6 pt-4">
          <ActionIcon :action="selectedAction" class="pt-4" />
          {{ nodeType }}
        </p>

        <v-divider class="mx-n4 mb-6 GeneralActionSettings__divider" />

        <section>
          <v-text-field
              outlined
              dense
              label="Name"
              hide-details="auto"
              :value="selectedAction.name"
              @input="(val) => updateSelectedAction(val, 'name')"
              class="mb-4"
          ></v-text-field>
        </section>

        <section v-if="showAuthentication">
          <v-select
              class="mb-4"
              outlined
              dense
              :value="selectedAction.settings.authenticationId"
              @input="
              (val) => updateSelectedAction(val, 'settings.authenticationId')
            "
              :items="authentications"
              item-text="name"
              item-value="id"
              label="Authentication"
              clearable
          ></v-select>
        </section>

        <component
            :is="getConnectorComponent"
            :setInputOutput="setInputOutput"
            @update="updateSelectedAction"
        />

        <div>
          <div style="margin-bottom: 15px; margin-top: 10px">
            <span>Action Schema: </span>
          </div>
          <json-viewer
              v-if="this.actionSchema !== null && this.actionSchema != ''"
              :value="this.actionSchema"
              :expand-depth="0"
              boxed
              sort
              copyable
          ></json-viewer>
        </div>

        <output-model :model="outputType" />
      </div>

      <v-card-actions class="action-buttons">
        <v-btn color="blue darken-1" text @click="saveAction">
          <v-icon small>mdi-check</v-icon>
          Done
        </v-btn>

        <div v-if="nodeIsModified" class="discard_button">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="blue darken-1"
                  small
                  text
                  @click="resetNode"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon small>mdi-undo-variant</v-icon>
                Discard
              </v-btn>
            </template>
            <span>Discard unsaved changes</span>
          </v-tooltip>
        </div>

        <v-btn text color="error lighten-1" @click.stop="deleteNode">
          <v-icon small>mdi-delete</v-icon>
          Remove
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import lodashSet from 'lodash/set';
import isEqual from 'lodash/isEqual';
import lodashGet from 'lodash/get';

import {
  findNode,
} from '@/util/action-types';
import { getAction } from '@/util/get-connectors';
import OutputModel from '@/components/output-model/output-model.vue';
import ActionIcon from '@/components/action-icon/action-icon.vue';
import OneDriveFileUpload from "@/views/ai-workspaces/workspace/general-actions/action-settings/actions/onedrive/upload-file.vue";
import OneDriveDownloadFile from "@/views/ai-workspaces/workspace/general-actions/action-settings/actions/onedrive/download-file.vue";
import OneDriveMoveFile from "@/views/ai-workspaces/workspace/general-actions/action-settings/actions/onedrive/move-file.vue";
import OneDriveGetFolder from "@/views/ai-workspaces/workspace/general-actions/action-settings/actions/onedrive/get-folder.vue";
import OneDriveCreateFolder from "@/views/ai-workspaces/workspace/general-actions/action-settings/actions/onedrive/create-folder.vue";

const { mapGetters: credentialsGetters } =
    createNamespacedHelpers('credentials');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const { mapGetters: workspacesGetters, mapActions: workspaceActions, mapMutations: workspaceMutations } = createNamespacedHelpers('aiWorkspace');
const { mapGetters: workflowsGetters, mapActions: workflowActions } = createNamespacedHelpers('workflows/details');
import JsonViewer from 'vue-json-viewer';

export default {
  name: 'GeneralActionSettings',
  components: {
    OutputModel,
    ActionIcon,
    JsonViewer
  },
  data() {
    return {
      copyOfSelectedAction: null,
      authenticatedActions: [
        'browser_auto',
        'raw_browser_auto',
        'short_browser_auto_playwright',
        'long_browser_auto_playwright',
        'execute_raw_query',
        'aws_s3_upload',
        'aws_send_sqs',
        'execute_http_request',
        'sftp_upload',
        'sftp_download',
        'google_drive_upload_file',
        'google_sheets_read_data',
        'google_sheets_write_data',
        'msgraph_onedrive_upload_file',
        'msgraph_onedrive_download_file',
        'google_drive_download_file',
        'msgraph_workbook_read_data',
        'msgraph_workbook_write_data',
        'google_gmail_send_email',
        'msgraph_mail_send_email',
        'get_benefits_pverify',
        'eligibiltiy_pdf_report_pverify',
        'get_inquiry_pverify',
        'browser_auto_webdriver_js',
        'twilio_send_sms',
        'twilio_list_sms',
        'twilio_send_email',
        'google_gmail_read_email',
        'msgraph_mail_read_email',
        'msgraph_onedrive_create_folder',
        'msgraph_onedrive_get_folder',
        'msgraph_onedrive_move_file',
        'task_update_patient_insurance',
        'msgraph_workbook_update_cell',
      ],
      actionSchema: null,
    };
  },
  watch: {
    selectedAction: {
      async handler(newNode, oldNode) {
        if(!newNode) {
          return;
        }
        const oldInput = oldNode?.input;
        const newInput = newNode?.input;

        if (this.GeneralActionSettings && !isEqual(newInput, oldInput)) {
          this.checkIfInputIsValid();
        }

        this.getActionSchema({ actionType: newNode.action_type }).then((data) => {
          this.actionSchema = data
        })

        if(!this.actionsOutputs) {
          await this.fetchOutputModels()
        }

        if (
            !this.selectedAction.output_type &&
            this.actionsOutputs[this.selectedAction.action_type]
        ) {
          const output_type = this.actionsOutputs[this.action];
          this.updateSelectedAction(output_type, 'output_type');
        }
      },
      deep: true,
    },
  },
  async mounted() {
    if(!this.selectedAction) {
      return;
    }
    const selectedAction = cloneDeep(this.selectedAction);
    try {
      this.fetchOutputModels().then(() => {
        if (
            !selectedAction.output_type &&
            this.actionsOutputs[selectedAction.action_type]
        ) {
          const output_type = this.actionsOutputs[this.action];
          this.updateSelectedAction(output_type, 'output_type');
        }
      });
    } catch (e) {
      console.log(e);
    }
  },
  computed: {
    ...credentialsGetters({
      authentications: 'AUTHENTICATIONS',
    }),
    ...workspacesGetters({
      selectedAction: 'SELECTED_ACTION',
      generalActions: 'WORKSPACE_GENERAL_ACTIONS',
    }),
    ...workflowsGetters({
      actionsOutputs: 'ACTION_OUTPUTS',
    }),
    ...customerGetters({
      customer: 'CUSTOMER',
    }),
    defaultNodeSettings() {
      const { action_type } = this.selectedAction;
      const { data_source_name } = this.customer.CustomersFeature;
      return getAction(action_type, data_source_name);
    },
    nodeIsModified() {
      return !isEqual(this.selectedAction, this.copyOfSelectedAction);
    },
    GeneralActionSettings() {
      return lodashGet(this.selectedAction, 'input');
    },
    action() {
      return this.selectedAction.action_type;
    },
    subject() {
      return this.selectedAction.subject;
    },
    getConnectorComponent() {
      switch (this.action) {
        case 'msgraph_onedrive_upload_file':
          return OneDriveFileUpload;
        case 'msgraph_onedrive_download_file':
          return OneDriveDownloadFile;
        case 'msgraph_onedrive_get_folder':
          return OneDriveMoveFile;
        case 'msgraph_onedrive_move_file':
          return OneDriveGetFolder;
        case 'msgraph_onedrive_create_folder':
          return OneDriveCreateFolder;
        default:
          return null
      }
    },
    showAuthentication() {
      return this.authenticatedActions.includes(this.selectedAction.action_type);
    },
    inputNode() {
      if (!this.selectedAction || !this.GeneralActionSettings) return null;
      const inputNodeId = this.GeneralActionSettings.inputNodeId;
      if (!inputNodeId) return null;

      return this.findAction(inputNodeId);
    },
    findAction(id) {
      return findNode(this.nodes, id);
    },
    inputAction() {
      const inputNode = this.inputNode;
      if (
          !inputNode ||
          !inputNode.action_type ||
          !inputNode.actions ||
          !inputNode.actions.length
      )
        return null;
      return inputNode.actions.find(
          (a) => inputNode.action_type === a.action_type
      );
    },
    inputModel() {
      if (this.inputNode && this.inputNode.output_type) {
        return this.inputNode.output_type;
      } else if (this.inputAction && this.inputAction.output_type) {
        return this.inputAction.output_type;
      } else {
        return null;
      }
    },
    selectedActionConnector() {
      const sn = this.selectedAction;
      if (!sn || !sn.action_type || !sn.actions || !sn.actions.length)
        return null;
      return sn.actions.find((a) => sn.action_type === a.action_type);
    },
    outputType() {
      if (this.selectedAction && this.selectedAction.output_type)
        return this.selectedAction.output_type;
      if (this.selectedAction && this.selectedAction.output_type)
        return this.selectedAction.output_type;
      return null;
    },
    iconColor() {
      return this.selectedAction && this.selectedAction.color
          ? this.selectedAction.color
          : '#000';
    },
    nodeType() {
      return (
          this.selectedAction.display_name ||
          (this.selectedAction.action_type || '').split('_').join(' ') // fallback for existing workflows/connectors
      );
    },
  },
  methods: {
    ...workflowActions(['fetchOutputModels']),
    ...workspaceMutations({
      setSelectedAction: 'SET_SELECTED_ACTION',
      setGeneralActions: 'SET_GENERAL_ACTIONS',
    }),
    ...workspaceActions(['getActionSchema']),
    updateSelectedAction(value, path) {
      const selectedAction = cloneDeep(this.selectedAction);
      const updatedNode = lodashSet(selectedAction, path, value);
      this.setSelectedAction(updatedNode);
      const index = this.generalActions.findIndex((action) => action.id === updatedNode.id);
      const generalActions = this.generalActions;
      generalActions[index] = updatedNode;
      this.setGeneralActions(generalActions);
    },
    async closeModal() {
      this.setSelectedAction(null);
    },
    deleteNode() {
      const node = cloneDeep(this.selectedAction);
      const actions = this.generalActions.filter((action) => action.action_type !== node.action_type);
      this.setGeneralActions(actions);
      this.setSelectedAction(null);
      this.$emit('updateGeneralActions');
    },
    saveAction() {
      this.closeModal();
      this.$emit('updateGeneralActions');
    },
    resetNode() {
      let node = cloneDeep(this.copyOfSelectedAction);
      if (!this.copyOfSelectedAction) {
        node = cloneDeep(this.selectedAction);
        node.input = cloneDeep(this.defaultNodeSettings).input;
        node.path = cloneDeep(this.selectedAction.path);
        node.settings = cloneDeep(this.defaultNodeSettings).settings;
      }

      this.setSelectedAction(node);
    },
    changeInput() {
      const { input } = cloneDeep(this.selectedAction);

      if (this.availableInputFields && this.availableInputFields.length) {
          input.inputFieldPath = this.inputModel?.name;
          input.inputFieldName = this.inputModel?.name;
          input.inputFieldId = this.inputModel?.uid;
          this.updateSelectedAction(input, 'input');
      }
    },
    setInputOutput(uid) {
      const input = cloneDeep(this.selectedAction.input);

      const selectedOption = this.availableInputFields.find(
          (f) => f.uid === uid
      );
      if (!selectedOption) {
        return this.changeInput();
      }
      const field = selectedOption.field;
      input.inputFieldPath = selectedOption.name;
      input.inputFieldName = field.name;
      input.inputFieldId = field.uid;
      this.updateSelectedAction(input, 'input');
    },
    checkIfInputIsValid() {
      let selectedAction = cloneDeep(this.selectedActionConnector);

      if (!selectedAction?.input?.inputNodeId) return;
      // check if input was not deleted
      const inputNode = this.findNode(selectedAction.input.inputNodeId);
      const selectedAct =
          inputNode &&
          inputNode.actions &&
          inputNode.actions.find((a) => a.action_type === inputNode.action_type);
      if (!inputNode) {
        const snn = cloneDeep(selectedAction);
        snn.input.inputNodeId = null;
        snn.input.inputActionType = null;
        snn.output_type = null;
      } else if (!inputNode.output_type && !selectedAct) {
        selectedAction.output_type = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import './action-settings';
</style>
